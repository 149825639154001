import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "min-h-screen bg-[#1C1C1E] text-white" }

import { Award, BarChart, GitBranch, Gift } from 'lucide-vue-next'
import TheHeader from '@/components/landing/TheHeader.vue'
import HeroSection from '@/components/landing/HeroSection.vue'
import FeaturesSection from '@/components/landing/FeaturesSection.vue'
// import TestimonialsSection from '@/components/landing/TestimonialsSection.vue'
import CTASection from '@/components/landing/CTASection.vue'
import TheFooter from '@/components/landing/TheFooter.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'LandingPage',
  setup(__props) {

const navLinks = []

const features = [
  {
    icon: Award,
    title: 'Automated Recognition',
    description: 'Automatically detect and celebrate team achievements in real-time.'
  },
  {
    icon: BarChart,
    title: 'Performance Insights',
    description: 'Get AI-powered analytics on team performance and engagement.'
  },
  {
    icon: GitBranch,
    title: 'Seamless Integration',
    description: 'Connect with GitLab, GitHub, Jira, and Salesforce effortlessly.'
  },
  {
    icon: Gift,
    title: 'Custom Rewards',
    description: 'Create personalized reward programs that motivate your team.'
  }
]

const testimonials = [
  {
    quote: 'Achify has transformed how we recognize and reward our team\'s achievements.',
    name: 'Sarah Chen',
    role: 'HR Director',
    initials: 'SC'
  },
  {
    quote: 'The automated recognition features have boosted our team\'s morale significantly.',
    name: 'Michael Ross',
    role: 'Engineering Lead',
    initials: 'MR'
  },
  {
    quote: 'Integration with our existing tools made adoption seamless across departments.',
    name: 'Jessica Lee',
    role: 'Operations Manager',
    initials: 'JL'
  }
]

return (_ctx: any,_cache: any) => {
  const _component_TestimonialsSection = _resolveComponent("TestimonialsSection")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(TheHeader, { navLinks: navLinks }),
    _createVNode(HeroSection),
    _createVNode(FeaturesSection, { features: features }),
    _createVNode(_component_TestimonialsSection, { testimonials: testimonials }),
    _createVNode(CTASection),
    _createVNode(TheFooter)
  ]))
}
}

})