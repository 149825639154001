import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "py-20 bg-gradient-to-b from-[#184A45]/20 to-transparent" }
const _hoisted_2 = { class: "container mx-auto px-4 text-center" }
const _hoisted_3 = {
  class: "max-w-2xl mx-auto",
  initial: { opacity: 0 },
  enter: { opacity: 1 }
}

import { useRouter } from 'vue-router'

export default /*@__PURE__*/_defineComponent({
  __name: 'CTASection',
  setup(__props) {

const router = useRouter()

return (_ctx: any,_cache: any) => {
  const _directive_motion = _resolveDirective("motion")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
        _cache[1] || (_cache[1] = _createElementVNode("h3", { class: "text-3xl font-bold mb-6" }, "Ready to Transform Your Workplace?", -1)),
        _cache[2] || (_cache[2] = _createElementVNode("p", { class: "text-xl text-gray-300 mb-8" }, " Join our beta program and be among the first to experience the future of employee recognition. ", -1)),
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push('/contact'))),
          class: "bg-[#D48F5A] hover:bg-[#D48F5A]/90 px-8 py-4 rounded-full text-lg font-semibold transition-all transform hover:scale-105"
        }, " Get Started Now ")
      ])), [
        [_directive_motion]
      ])
    ])
  ]))
}
}

})