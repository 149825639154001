import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "min-h-screen bg-[#1C1C1E] text-white pt-20" }

import ContactForm from '@/components/contact/ContactForm.vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'ContactPage',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(ContactForm)
  ]))
}
}

})