import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "py-20 bg-[#1C1C1E]/50" }
const _hoisted_2 = { class: "container mx-auto px-4" }
const _hoisted_3 = { class: "grid md:grid-cols-2 lg:grid-cols-4 gap-8" }
const _hoisted_4 = ["enter"]
const _hoisted_5 = { class: "text-xl font-semibold mb-3" }
const _hoisted_6 = { class: "text-gray-400" }

import type { Component } from 'vue'

type Feature = {
  icon: Component
  title: string
  description: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FeaturesSection',
  props: {
    features: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  const _directive_motion = _resolveDirective("motion")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "text-3xl font-bold text-center mb-16" }, "Powerful Features for Modern Teams", -1)),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.features, (feature, index) => {
          return _withDirectives((_openBlock(), _createElementBlock("div", {
            key: index,
            class: "p-6 rounded-xl bg-[#184A45]/10 border border-[#184A45]/20 hover:border-[#D48F5A]/50 transition-all",
            initial: { opacity: 0, y: 50 },
            enter: { opacity: 1, y: 0, transition: { delay: index * 200 } }
          }, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(feature.icon), { class: "w-12 h-12 mb-4 text-[#D48F5A]" })),
            _createElementVNode("h4", _hoisted_5, _toDisplayString(feature.title), 1),
            _createElementVNode("p", _hoisted_6, _toDisplayString(feature.description), 1)
          ], 8, _hoisted_4)), [
            [_directive_motion]
          ])
        }), 128))
      ])
    ])
  ]))
}
}

})