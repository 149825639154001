<template>
  <section class="py-20">
    <div class="container mx-auto px-4">
      <div class="max-w-2xl mx-auto mb-8">
        <router-link 
          to="/" 
          class="inline-flex items-center text-gray-400 hover:text-[#D48F5A] transition-colors"
        >
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            class="h-5 w-5 mr-2" 
            viewBox="0 0 20 20" 
            fill="currentColor"
          >
            <path 
              fill-rule="evenodd" 
              d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" 
              clip-rule="evenodd" 
            />
          </svg>
          Back to Home
        </router-link>
      </div>

      <div class="max-w-2xl mx-auto"
           v-motion
           :initial="{ opacity: 0, y: 50 }"
           :enter="{ opacity: 1, y: 0 }">
        <h1 class="text-4xl font-bold text-center mb-8">Request Early Access</h1>
        <p class="text-gray-400 text-center mb-12">
          Join our exclusive beta program and be among the first to experience the future of employee recognition. 
          Limited spots available.
        </p>

        <form @submit.prevent="handleSubmit" class="space-y-6">
          <div class="space-y-2">
            <label for="email" class="block text-sm font-medium text-gray-300">Work Email</label>
            <input
              id="email"
              v-model="form.email"
              type="email"
              required
              placeholder="you@company.com"
              class="w-full px-4 py-3 rounded-lg bg-[#2F4858]/20 border border-[#2F4858] 
                     focus:border-[#D48F5A] focus:ring-1 focus:ring-[#D48F5A] 
                     transition-colors text-white"
              :class="{ 'border-red-500': errors.email }"
            />
            <p v-if="errors.email" class="text-red-500 text-sm">{{ errors.email }}</p>
          </div>

          <div class="space-y-2">
            <label for="company" class="block text-sm font-medium text-gray-300">Company Name</label>
            <input
              id="company"
              v-model="form.company"
              type="text"
              required
              placeholder="Your company"
              class="w-full px-4 py-3 rounded-lg bg-[#2F4858]/20 border border-[#2F4858] 
                     focus:border-[#D48F5A] focus:ring-1 focus:ring-[#D48F5A] 
                     transition-colors text-white"
              :class="{ 'border-red-500': errors.company }"
            />
            <p v-if="errors.company" class="text-red-500 text-sm">{{ errors.company }}</p>
          </div>

          <div class="space-y-2">
            <label for="phone" class="block text-sm font-medium text-gray-300">Contact Number</label>
            <input
              id="phone"
              v-model="form.phone"
              type="tel"
              required
              placeholder="+1 (555) 000-0000"
              class="w-full px-4 py-3 rounded-lg bg-[#2F4858]/20 border border-[#2F4858] 
                     focus:border-[#D48F5A] focus:ring-1 focus:ring-[#D48F5A] 
                     transition-colors text-white"
              :class="{ 'border-red-500': errors.phone }"
            />
            <p v-if="errors.phone" class="text-red-500 text-sm">{{ errors.phone }}</p>
          </div>

          <div class="pt-4">
            <button
              type="submit"
              :disabled="isSubmitting"
              class="w-full px-6 py-3 text-lg font-semibold text-white bg-[#D48F5A] 
                     rounded-lg hover:bg-[#D48F5A]/90 transition-colors
                     disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <span v-if="isSubmitting">Processing...</span>
              <span v-else>Join Beta Program</span>
            </button>
          </div>
        </form>
      </div>

      <div 
        v-if="isSuccess"
        class="fixed top-4 right-4 bg-green-500 text-white px-6 py-3 rounded-lg shadow-lg"
        v-motion
        :initial="{ opacity: 0, y: -50 }"
        :enter="{ opacity: 1, y: 0 }"
        :leave="{ opacity: 0, y: -50 }"
      >
        Request submitted successfully! We'll be in touch soon.
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { ref, reactive } from 'vue'

interface FormData {
  email: string
  company: string
  phone: string
}

interface FormErrors {
  email?: string
  company?: string
  phone?: string
}

const form = reactive<FormData>({
  email: '',
  company: '',
  phone: ''
})

const errors = reactive<FormErrors>({})
const isSubmitting = ref(false)
const isSuccess = ref(false)

const validateEmail = (email: string): boolean => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return re.test(email)
}

const validateForm = (): boolean => {
  errors.email = !form.email.trim() 
    ? 'Email is required' 
    : !validateEmail(form.email) 
    ? 'Please enter a valid email' 
    : undefined
  errors.company = !form.company.trim() ? 'Company is required' : undefined
  errors.phone = !form.phone.trim() ? 'Phone number is required' : undefined

  return !Object.values(errors).some(error => error !== undefined)
}

const handleSubmit = async () => {
  if (!validateForm()) return

  isSubmitting.value = true

  try {
    const response = await fetch('https://hooks.zapier.com/hooks/catch/21856753/2gzqx5z/', {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        'Accept': 'application/json',
      },
      body: JSON.stringify({
        'contact': {
          email: form.email,
          company: form.company,
          phone: form.phone
        }
      })
    })

    // Reset form after successful submission
    Object.assign(form, {
      email: '',
      company: '',
      phone: ''
    })

    isSuccess.value = true
    setTimeout(() => {
      isSuccess.value = false
    }, 5000)

  } catch (error) {
    console.error('Error:', error)
    alert('Failed to send message. Please try again.')
  } finally {
    isSubmitting.value = false
  }
}
</script> 