<template>
  <div class="min-h-screen bg-[#1C1C1E] text-white">
    <TheHeader :navLinks="navLinks" />
    <HeroSection />
    <FeaturesSection :features="features" />
    <TestimonialsSection :testimonials="testimonials" />
    <CTASection />
    <TheFooter />
  </div>
</template>

<script setup lang="ts">
import { Award, BarChart, GitBranch, Gift } from 'lucide-vue-next'
import TheHeader from '@/components/landing/TheHeader.vue'
import HeroSection from '@/components/landing/HeroSection.vue'
import FeaturesSection from '@/components/landing/FeaturesSection.vue'
// import TestimonialsSection from '@/components/landing/TestimonialsSection.vue'
import CTASection from '@/components/landing/CTASection.vue'
import TheFooter from '@/components/landing/TheFooter.vue'

const navLinks = []

const features = [
  {
    icon: Award,
    title: 'Automated Recognition',
    description: 'Automatically detect and celebrate team achievements in real-time.'
  },
  {
    icon: BarChart,
    title: 'Performance Insights',
    description: 'Get AI-powered analytics on team performance and engagement.'
  },
  {
    icon: GitBranch,
    title: 'Seamless Integration',
    description: 'Connect with GitLab, GitHub, Jira, and Salesforce effortlessly.'
  },
  {
    icon: Gift,
    title: 'Custom Rewards',
    description: 'Create personalized reward programs that motivate your team.'
  }
]

const testimonials = [
  {
    quote: 'Achify has transformed how we recognize and reward our team\'s achievements.',
    name: 'Sarah Chen',
    role: 'HR Director',
    initials: 'SC'
  },
  {
    quote: 'The automated recognition features have boosted our team\'s morale significantly.',
    name: 'Michael Ross',
    role: 'Engineering Lead',
    initials: 'MR'
  },
  {
    quote: 'Integration with our existing tools made adoption seamless across departments.',
    name: 'Jessica Lee',
    role: 'Operations Manager',
    initials: 'JL'
  }
]
</script> 