<template>
  <section class="py-20 bg-gradient-to-b from-[#184A45]/20 to-transparent">
    <div class="container mx-auto px-4 text-center">
      <div class="max-w-2xl mx-auto"
           v-motion
           :initial="{ opacity: 0 }"
           :enter="{ opacity: 1 }">
        <h3 class="text-3xl font-bold mb-6">Ready to Transform Your Workplace?</h3>
        <p class="text-xl text-gray-300 mb-8">
          Join our beta program and be among the first to experience the future of employee recognition.
        </p>
        <button 
          @click="router.push('/contact')"
          class="bg-[#D48F5A] hover:bg-[#D48F5A]/90 px-8 py-4 rounded-full text-lg 
                font-semibold transition-all transform hover:scale-105">
          Get Started Now
        </button>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'
const router = useRouter()
</script> 