import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = { class: "py-20" }
const _hoisted_2 = { class: "container mx-auto px-4" }
const _hoisted_3 = { class: "max-w-2xl mx-auto mb-8" }
const _hoisted_4 = {
  class: "max-w-2xl mx-auto",
  initial: { opacity: 0, y: 50 },
  enter: { opacity: 1, y: 0 }
}
const _hoisted_5 = { class: "space-y-2" }
const _hoisted_6 = {
  key: 0,
  class: "text-red-500 text-sm"
}
const _hoisted_7 = { class: "space-y-2" }
const _hoisted_8 = {
  key: 0,
  class: "text-red-500 text-sm"
}
const _hoisted_9 = { class: "space-y-2" }
const _hoisted_10 = {
  key: 0,
  class: "text-red-500 text-sm"
}
const _hoisted_11 = { class: "pt-4" }
const _hoisted_12 = ["disabled"]
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = {
  key: 0,
  class: "fixed top-4 right-4 bg-green-500 text-white px-6 py-3 rounded-lg shadow-lg",
  initial: { opacity: 0, y: -50 },
  enter: { opacity: 1, y: 0 },
  leave: { opacity: 0, y: -50 }
}

import { ref, reactive } from 'vue'

interface FormData {
  email: string
  company: string
  phone: string
}

interface FormErrors {
  email?: string
  company?: string
  phone?: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ContactForm',
  setup(__props) {

const form = reactive<FormData>({
  email: '',
  company: '',
  phone: ''
})

const errors = reactive<FormErrors>({})
const isSubmitting = ref(false)
const isSuccess = ref(false)

const validateEmail = (email: string): boolean => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return re.test(email)
}

const validateForm = (): boolean => {
  errors.email = !form.email.trim() 
    ? 'Email is required' 
    : !validateEmail(form.email) 
    ? 'Please enter a valid email' 
    : undefined
  errors.company = !form.company.trim() ? 'Company is required' : undefined
  errors.phone = !form.phone.trim() ? 'Phone number is required' : undefined

  return !Object.values(errors).some(error => error !== undefined)
}

const handleSubmit = async () => {
  if (!validateForm()) return

  isSubmitting.value = true

  try {
    const response = await fetch('https://hooks.zapier.com/hooks/catch/21856753/2gzqx5z/', {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        'Accept': 'application/json',
      },
      body: JSON.stringify({
        'contact': {
          email: form.email,
          company: form.company,
          phone: form.phone
        }
      })
    })

    // Reset form after successful submission
    Object.assign(form, {
      email: '',
      company: '',
      phone: ''
    })

    isSuccess.value = true
    setTimeout(() => {
      isSuccess.value = false
    }, 5000)

  } catch (error) {
    console.error('Error:', error)
    alert('Failed to send message. Please try again.')
  } finally {
    isSubmitting.value = false
  }
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_motion = _resolveDirective("motion")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, {
          to: "/",
          class: "inline-flex items-center text-gray-400 hover:text-[#D48F5A] transition-colors"
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createElementVNode("svg", {
              xmlns: "http://www.w3.org/2000/svg",
              class: "h-5 w-5 mr-2",
              viewBox: "0 0 20 20",
              fill: "currentColor"
            }, [
              _createElementVNode("path", {
                "fill-rule": "evenodd",
                d: "M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z",
                "clip-rule": "evenodd"
              })
            ], -1),
            _createTextVNode(" Back to Home ")
          ])),
          _: 1
        })
      ]),
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, [
        _cache[7] || (_cache[7] = _createElementVNode("h1", { class: "text-4xl font-bold text-center mb-8" }, "Request Early Access", -1)),
        _cache[8] || (_cache[8] = _createElementVNode("p", { class: "text-gray-400 text-center mb-12" }, " Join our exclusive beta program and be among the first to experience the future of employee recognition. Limited spots available. ", -1)),
        _createElementVNode("form", {
          onSubmit: _withModifiers(handleSubmit, ["prevent"]),
          class: "space-y-6"
        }, [
          _createElementVNode("div", _hoisted_5, [
            _cache[4] || (_cache[4] = _createElementVNode("label", {
              for: "email",
              class: "block text-sm font-medium text-gray-300"
            }, "Work Email", -1)),
            _withDirectives(_createElementVNode("input", {
              id: "email",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.email) = $event)),
              type: "email",
              required: "",
              placeholder: "you@company.com",
              class: _normalizeClass(["w-full px-4 py-3 rounded-lg bg-[#2F4858]/20 border border-[#2F4858] focus:border-[#D48F5A] focus:ring-1 focus:ring-[#D48F5A] transition-colors text-white", { 'border-red-500': errors.email }])
            }, null, 2), [
              [_vModelText, form.email]
            ]),
            (errors.email)
              ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(errors.email), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[5] || (_cache[5] = _createElementVNode("label", {
              for: "company",
              class: "block text-sm font-medium text-gray-300"
            }, "Company Name", -1)),
            _withDirectives(_createElementVNode("input", {
              id: "company",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((form.company) = $event)),
              type: "text",
              required: "",
              placeholder: "Your company",
              class: _normalizeClass(["w-full px-4 py-3 rounded-lg bg-[#2F4858]/20 border border-[#2F4858] focus:border-[#D48F5A] focus:ring-1 focus:ring-[#D48F5A] transition-colors text-white", { 'border-red-500': errors.company }])
            }, null, 2), [
              [_vModelText, form.company]
            ]),
            (errors.company)
              ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(errors.company), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _cache[6] || (_cache[6] = _createElementVNode("label", {
              for: "phone",
              class: "block text-sm font-medium text-gray-300"
            }, "Contact Number", -1)),
            _withDirectives(_createElementVNode("input", {
              id: "phone",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((form.phone) = $event)),
              type: "tel",
              required: "",
              placeholder: "+1 (555) 000-0000",
              class: _normalizeClass(["w-full px-4 py-3 rounded-lg bg-[#2F4858]/20 border border-[#2F4858] focus:border-[#D48F5A] focus:ring-1 focus:ring-[#D48F5A] transition-colors text-white", { 'border-red-500': errors.phone }])
            }, null, 2), [
              [_vModelText, form.phone]
            ]),
            (errors.phone)
              ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(errors.phone), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("button", {
              type: "submit",
              disabled: isSubmitting.value,
              class: "w-full px-6 py-3 text-lg font-semibold text-white bg-[#D48F5A] rounded-lg hover:bg-[#D48F5A]/90 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            }, [
              (isSubmitting.value)
                ? (_openBlock(), _createElementBlock("span", _hoisted_13, "Processing..."))
                : (_openBlock(), _createElementBlock("span", _hoisted_14, "Join Beta Program"))
            ], 8, _hoisted_12)
          ])
        ], 32)
      ])), [
        [_directive_motion]
      ]),
      (isSuccess.value)
        ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_15, _cache[9] || (_cache[9] = [
            _createTextVNode(" Request submitted successfully! We'll be in touch soon. ")
          ]))), [
            [_directive_motion]
          ])
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})