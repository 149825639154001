import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, unref as _unref } from "vue"

const _hoisted_1 = { class: "fixed w-full top-0 z-50 bg-[#1C1C1E]/80 backdrop-blur-lg" }
const _hoisted_2 = { class: "container mx-auto px-4 py-4 flex items-center justify-between" }
const _hoisted_3 = { class: "flex items-center" }
const _hoisted_4 = { class: "hidden md:flex items-center space-x-8" }

import { useRouter } from 'vue-router'

export default /*@__PURE__*/_defineComponent({
  __name: 'TheHeader',
  props: {
    navLinks: {}
  },
  setup(__props: any) {

const router = useRouter()


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("nav", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, {
          to: "/",
          class: "text-2xl font-bold text-white"
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("Achify")
          ])),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navLinks, (link) => {
          return (_openBlock(), _createBlock(_component_router_link, {
            key: link,
            to: link.toLowerCase(),
            class: "text-gray-300 hover:text-[#D48F5A] transition-colors"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(link), 1)
            ]),
            _: 2
          }, 1032, ["to"]))
        }), 128))
      ]),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push('/contact'))),
        class: "bg-[#D48F5A] hover:bg-[#D48F5A]/90 px-6 py-2 rounded-full transition-all transform hover:scale-105"
      }, " Join the Beta ")
    ])
  ]))
}
}

})