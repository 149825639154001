<template>
  <header class="fixed w-full top-0 z-50 bg-[#1C1C1E]/80 backdrop-blur-lg">
    <nav class="container mx-auto px-4 py-4 flex items-center justify-between">
      <div class="flex items-center">
        <router-link to="/" class="text-2xl font-bold text-white">Achify</router-link>
      </div>
      
      <div class="hidden md:flex items-center space-x-8">
        <router-link 
          v-for="link in navLinks" 
          :key="link"
          :to="link.toLowerCase()"
          class="text-gray-300 hover:text-[#D48F5A] transition-colors"
        >
          {{ link }}
        </router-link>
      </div>

      <button 
        @click="router.push('/contact')"
        class="bg-[#D48F5A] hover:bg-[#D48F5A]/90 px-6 py-2 rounded-full 
               transition-all transform hover:scale-105">
        Join the Beta
      </button>
    </nav>
  </header>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'
const router = useRouter()
defineProps<{
  navLinks: string[]
}>()
</script> 