<template>
  <section class="min-h-screen flex items-center pt-20 relative overflow-hidden">
    <div class="container mx-auto px-4">
      <div class="max-w-3xl mx-auto text-center"
           v-motion
           :initial="{ opacity: 0, y: 100 }"
           :enter="{ opacity: 1, y: 0 }">
        <h2 class="text-4xl md:text-6xl font-bold mb-6 bg-gradient-to-r from-[#184A45] to-[#2F4858] 
                   bg-clip-text text-transparent">
          Boost Team Performance with AI-Powered Recognition
        </h2>
        <p class="text-xl text-gray-300 mb-8">
          Join leading companies using Achify to automatically celebrate achievements, 
          increase retention, and build a high-performing culture.
        </p>
        <button 
          @click="router.push('/contact')"
          class="bg-[#D48F5A] hover:bg-[#D48F5A]/90 px-8 py-4 rounded-full text-lg 
                 font-semibold transition-all transform hover:scale-105">
          Get Early Access →
        </button>
      </div>
    </div>
    
    <!-- Background decoration -->
    <div class="absolute -z-10 w-full h-full">
      <div class="absolute top-1/4 left-1/4 w-96 h-96 bg-[#184A45]/20 rounded-full blur-3xl"></div>
      <div class="absolute bottom-1/4 right-1/4 w-96 h-96 bg-[#2F4858]/20 rounded-full blur-3xl"></div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'
const router = useRouter()
</script> 