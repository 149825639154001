<template>
  <footer class="py-12 border-t border-gray-800">
    <div class="container mx-auto px-4">
      <div class="grid md:grid-cols-1 gap-8">
        <div>
          <h4 class="text-xl font-bold mb-4">Achify</h4>
          <p class="text-gray-400">Empowering teams through intelligent recognition</p>
        </div>
        <!-- <div>
          <h5 class="font-semibold mb-4">Product</h5>
          <ul class="space-y-2 text-gray-400">
            <li><a href="#" class="hover:text-[#D48F5A]">Features</a></li>
            <li><a href="#" class="hover:text-[#D48F5A]">Pricing</a></li>
            <li><a href="#" class="hover:text-[#D48F5A]">Beta Program</a></li>
          </ul>
        </div>
        <div>
          <h5 class="font-semibold mb-4">Company</h5>
          <ul class="space-y-2 text-gray-400">
            <li><a href="#" class="hover:text-[#D48F5A]">About</a></li>
            <li><a href="#" class="hover:text-[#D48F5A]">Contact</a></li>
            <li><a href="#" class="hover:text-[#D48F5A]">Privacy Policy</a></li>
          </ul>
        </div>
        <div>
          <h5 class="font-semibold mb-4">Connect</h5>
          <div class="flex space-x-4">
            <a href="#" class="text-gray-400 hover:text-[#D48F5A]">
              <i class="fab fa-twitter"></i>
            </a>
            <a href="#" class="text-gray-400 hover:text-[#D48F5A]">
              <i class="fab fa-linkedin"></i>
            </a>
            <a href="#" class="text-gray-400 hover:text-[#D48F5A]">
              <i class="fab fa-github"></i>
            </a>
          </div>
        </div> -->
      </div>
    </div>
  </footer>
</template> 