import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "min-h-screen flex items-center pt-20 relative overflow-hidden" }
const _hoisted_2 = { class: "container mx-auto px-4" }
const _hoisted_3 = {
  class: "max-w-3xl mx-auto text-center",
  initial: { opacity: 0, y: 100 },
  enter: { opacity: 1, y: 0 }
}

import { useRouter } from 'vue-router'

export default /*@__PURE__*/_defineComponent({
  __name: 'HeroSection',
  setup(__props) {

const router = useRouter()

return (_ctx: any,_cache: any) => {
  const _directive_motion = _resolveDirective("motion")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
        _cache[1] || (_cache[1] = _createElementVNode("h2", { class: "text-4xl md:text-6xl font-bold mb-6 bg-gradient-to-r from-[#184A45] to-[#2F4858] bg-clip-text text-transparent" }, " Boost Team Performance with AI-Powered Recognition ", -1)),
        _cache[2] || (_cache[2] = _createElementVNode("p", { class: "text-xl text-gray-300 mb-8" }, " Join leading companies using Achify to automatically celebrate achievements, increase retention, and build a high-performing culture. ", -1)),
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push('/contact'))),
          class: "bg-[#D48F5A] hover:bg-[#D48F5A]/90 px-8 py-4 rounded-full text-lg font-semibold transition-all transform hover:scale-105"
        }, " Get Early Access → ")
      ])), [
        [_directive_motion]
      ])
    ]),
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "absolute -z-10 w-full h-full" }, [
      _createElementVNode("div", { class: "absolute top-1/4 left-1/4 w-96 h-96 bg-[#184A45]/20 rounded-full blur-3xl" }),
      _createElementVNode("div", { class: "absolute bottom-1/4 right-1/4 w-96 h-96 bg-[#2F4858]/20 rounded-full blur-3xl" })
    ], -1))
  ]))
}
}

})